<template>
  <div class="Panel">
    <h1 class="text-h5 grey--text text-uppercase">Painel de Censuras</h1>
    <v-row>
      <v-col cols="12" sm="4" class="mt-4">
        <v-select
          v-model="selectedCampaign"
          :items="campaignsNames"
          label="Campanha"
          item-text="name"
          item-value="id"
          return-object
          dense
          @change="loadSelectedCampaign()"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="campaign">
          <v-card-title>
            {{ campaign.name }}
            <v-spacer></v-spacer>
            <v-fab-transition>
              <v-btn color="primary darken-2" dark fab absolute top right>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card-title>
          <v-card-subtitle>{{ campaign.client }}</v-card-subtitle>
          <v-card-text class="">
            <v-row>
              <v-col cols="12">
                <strong>Qtde.:</strong>
                <span>
                  {{ campaign.censuresData ? campaign.censuresData.length : 0 }}
                </span>
                <span class="mx-2"> | </span>
                <strong>P.i.:</strong>
                <span>
                  {{
                    campaign.piCamp
                      ? campaign.piCamp
                      : campaign.censuresData
                      ? campaign.censuresData[0].pi
                      : ""
                  }}
                </span>
                <span class="mx-2"> | </span>
                <strong>Período:</strong>
                <span>
                  {{
                    campaign.timeFrame
                      ? campaign.timeFrame
                      : campaign.censuresData
                      ? campaign.censuresData[0].periodo
                      : ""
                  }}
                </span>
                <span class="mx-2"> | </span>
                <strong>Tipo/Duração:</strong>
                <span>
                  {{
                    campaign.type
                      ? campaign.type
                      : campaign.censuresData
                      ? campaign.censuresData[0].tipo
                      : ""
                  }}/{{
                    campaign.format
                      ? campaign.format
                      : campaign.censuresData
                      ? campaign.censuresData[0].formato
                      : ""
                  }}
                </span>
              </v-col>
              <!-- <v-col
                cols="12"
                sm="6"
                md="5"
                lg="3"
                class="pb-0"
                :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }"
              >
                <p>
                  Conteúdo:
                  <a :href="campaign.contentUrl" target="_blank">{{
                    campaign.censuresData
                      ? campaign.censuresData[0].conteudo
                      : "Texto - T1"
                  }}</a>
                </p>
              </v-col> -->
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-data-table
              :headers="
                currentUser.role == 'admin'
                  ? headers
                  : headers.filter((header) => header.value != 'actions')
              "
              :items="campaign.censuresData"
              :search="search"
              show-expand
              :expanded.sync="expanded"
              :footer-props="{
                'items-per-page-all-text': 'Todas',
                'items-per-page-text': 'Censuras por página:',
                'items-per-page-options': [15, 50, 100, -1],
              }"
              sort-by="idCamp"
              class="elevation-1"
              item-key="idApp"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>CENSURAS</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Pesquisar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pb-8 pt-6 grey--text text--darken-1">
                  <strong>Programa:</strong> {{ item.programa }} <span class="mx-2"> | </span>
                  <strong>Apresentador:</strong> {{ item.apresentador }}
                  <span class="mx-2"> | </span> <strong>Hor. Início:</strong> {{ item.inicio }}
                  <span class="mx-2"> | </span> <strong>Hor. Fim:</strong> {{ item.fim
                  }}<span class="mx-2" v-if="item.audioData"> | </span
                  ><strong v-if="item.audioData">Operador: </strong>
                  <span v-if="item.audioData">{{ item.audioData.operatorName }}</span>
                </td>
              </template>
              <template v-slot:[`item.audio`]="{ item }">
                <div v-if="item.audioData">
                  <v-icon
                    small
                    :color="getColor(item.status)"
                    @click="editAudio(item)"
                    v-if="
                      currentUser.role == 'admin' ||
                      item.audioData.operatorId == currentUser.id ||
                      !item.audioData.operatorId
                    "
                  >
                    mdi-play-circle
                  </v-icon>
                  <v-icon small v-else> mdi-alert-octagon-outline </v-icon>
                  <v-icon
                    small
                    class="ml-2"
                    @click="publishItem(item)"
                    v-if="item.status == 1 && currentUser.role == 'admin'"
                  >
                    mdi-send-outline
                  </v-icon>
                  <v-icon
                    v-if="item.status >= 2 && currentUser.role == 'admin'"
                    small
                    class="ml-2"
                    @click="unPublishItem(item)"
                  >
                    mdi-check-bold
                  </v-icon>
                </div>
                <v-icon small @click="uploadAudio(item)" v-else> mdi-upload </v-icon>
              </template>
              <template v-slot:[`item.actions`]="{ item }" v-if="currentUser.role == 'admin'">
                <v-icon small class="mr-2" @click="cloneItem(item)"> mdi-content-duplicate </v-icon>
                <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
              <template v-slot:no-data>
                <p class="mt-4">
                  Nenhuma censura encontrada! Os registros cadastrados aparecerão aqui.
                </p>
              </template>
              <template v-slot:no-results>
                <p class="mt-4">Nenhuma resultado encontrado para busca: {{ search }}</p>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="currentUser.role == 'admin'"
              small
              dark
              color="primary"
              class="mb-2 ml-2"
              @click="importTableDialog = true"
              >Carregar Planilha</v-btn
            >
            <v-btn v-else small color="primary" class="mb-2 ml-2" disabled>Carregar Planilha</v-btn>
          </v-card-actions>
          <p class="float-right mt-2 mr-4 caption" :class="{ 'caption-play': !isMobile }">
            <v-icon small color="green">mdi-play-circle</v-icon> - Publicado
            <span class="mx-2"> | </span>
            <v-icon small color="orange">mdi-play-circle</v-icon> - Publicado com ressalvas (admin)
            <span class="mx-2"> | </span> <v-icon small color="red">mdi-play-circle</v-icon> - NÂO
            Publicado
          </p>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal edit -->
    <v-dialog scrollable v-model="editDialog" max-width="600px">
      <v-card v-if="selectedItem">
        <v-card-title>
          <span class="text-h5">Editar Censura - {{ selectedItem.idCamp }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Data veiculação"
                  v-model="selectedItem.veiculacao"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Agência" v-model="selectedItem.agencia"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Id Secom" v-model="selectedItem.idSecom"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Pi Veículo" v-model="selectedItem.piVeiculo"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Estado" v-model="selectedItem.uf"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Município" v-model="selectedItem.municipio"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Veículo" v-model="selectedItem.veiculo"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Programa" v-model="selectedItem.programa"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Apresentador"
                  v-model="selectedItem.apresentador"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Hor. Início" v-model="selectedItem.inicio"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Hor. Fim" v-model="selectedItem.fim"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Id Camp." v-model="selectedItem.idCamp"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="#. Inserção" v-model="selectedItem.numInsercao"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="#. Texto" v-model="selectedItem.numTexto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Conteúdo" v-model="selectedItem.conteudo"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="editDialog = false"> Cancelar </v-btn>
          <v-btn color="blue darken-1" text :loading="loading" @click="doEdit()"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal Upload -->
    <v-dialog persistent scrollable v-model="uploadDialog" max-width="650px">
      <v-card v-if="selectedItem">
        <v-btn
          icon
          :disabled="loading"
          @click="
            uploadDialog = false;
            clearAudio();
          "
          style="position: absolute; right: 5px; top: 5px"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-container>
          <v-row>
            <v-col cols="12" sm="8" class="pb-2">
              <v-card-title class="mb-2">
                <span
                  class="text-h5"
                  v-if="selectedItem.audioData && selectedItem.audioData.audioUrl"
                >
                  Áudio Edit - {{ selectedItem.veiculacao }} -
                  {{ selectedItem.numInsercao }}
                </span>
                <span class="text-h5" v-else>
                  Áudio Upload - {{ selectedItem.veiculacao }} -
                  {{ selectedItem.numInsercao }}
                </span>
              </v-card-title>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="pb-2"
              :class="{
                'ml-4': $vuetify.breakpoint.xsOnly,
                'py-0': $vuetify.breakpoint.xsOnly,
              }"
            >
              <v-menu
                v-if="selectedItem.audioData"
                ref="menu"
                v-model="timeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="selectedTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedTime"
                    label="Horário"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    style="width: 150px"
                    @click="audioFeedback = null"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeMenu"
                  v-model="selectedTime"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu.save(selectedTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="my-0 py-0">
            <v-col cols="12" class="py-0">
              <v-card-subtitle class="grey--text text--darken-1 py-0">
                <strong>Pi:</strong> <span>{{ selectedItem.piVeiculo }}</span> -
                <strong>Id Secom: </strong><span>{{ selectedItem.idSecom }}</span> -
                <strong>Rádio: </strong><span>{{ selectedItem.veiculo }}</span>
              </v-card-subtitle>
              <v-card-subtitle class="grey--text text--darken-1 py-0">
                <strong>Programa: </strong><span>{{ selectedItem.programa }}</span>
                <br />
                <strong>Conteúdo: </strong><span>{{ selectedItem.conteudo }}</span>
                <div class="float-right mr-4">
                  <b>Corte: </b>
                  <span :class="{ 'red--text': cutSeconds < 60 || cutSeconds > 75 }">
                    {{ cutSeconds }} "
                  </span>
                </div>
              </v-card-subtitle>
            </v-col>
            <v-col cols="12" class="mt-2 mb-0 pb-0" v-if="audioFeedback">
              <p class="red--text text-center">
                {{ audioFeedback }}
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-card-text>
          <v-form ref="uploadForm" v-model="isValid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <audio
                    id="audio-preview"
                    ref="audioPlayer"
                    controls
                    style="width: 100%"
                    :src="this.selectedItem.audioData ? this.selectedItem.audioData.audioUrl : ''"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <!-- <v-file-input
                    v-model="selectedAudio"
                    accept="audio/mpeg"
                    label="Selecione um arquivo de áudio"
                    prepend-icon="mdi-music"
                    @blur="audioFeedback = null"
                    @change="previewAudio()"
                    @click:clear="clearAudio()"
                  ></v-file-input> -->
                  <v-file-input
                    v-model="selectedAudio"
                    accept="audio/mpeg"
                    label="Selecione um arquivo de áudio"
                    prepend-icon="mdi-music"
                    persistent-hint
                    :hint="selectedAudio ? selectedAudio.name : ''"
                    @blur="audioFeedback = null"
                    @change="previewAudio()"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row v-if="selectedAudio || editingAudio ? true : false">
                <v-col cols="12" sm="9" class="pb-0 pt-4">
                  <v-text-field
                    v-model="selectedItem.apresentador"
                    :rules="[(v) => !!v || 'Insira o nome do apresentador desse áudio.']"
                    label="Apresentador"
                    class="pt-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="py-0">
                  <v-checkbox class="mt-2" v-model="locSubst" label="Substituto?"></v-checkbox>
                </v-col>
                <v-col cols="3" class="py-0">
                  <strong class="mb-2">Início</strong>
                  <v-text-field
                    label="Min."
                    :rules="[
                      (v) => v >= 0 || 'Horário inválido.',
                      (v) => v <= 60 || 'Horário inválido.',
                    ]"
                    v-model="corte.inicioMinutos"
                    class="pt-2"
                    type="number"
                    @blur="audioFeedback = null"
                    @change="audioFeedback = null"
                    @click="audioFeedback = null"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field
                    label="Seg."
                    :rules="[
                      (v) => v >= 0 || 'Horário inválido.',
                      (v) => v < 60 || 'Horário inválido.',
                    ]"
                    v-model="corte.inicioSegundos"
                    class="pt-0 mt-8"
                    type="number"
                    @blur="audioFeedback = null"
                    @change="audioFeedback = null"
                    @click="audioFeedback = null"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="py-0 ml-4">
                  <strong class="mb-2">Fim</strong>
                  <v-text-field
                    label="Min."
                    :rules="[
                      (v) => v >= 0 || 'Horário inválido.',
                      (v) => v <= 60 || 'Horário inválido.',
                      (v) => this.checkTime() || 'Hor. final < inicial.',
                    ]"
                    v-model="corte.fimMinutos"
                    class="pt-2"
                    type="number"
                    @blur="audioFeedback = null"
                    @change="audioFeedback = null"
                    @click="audioFeedback = null"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field
                    label="Seg."
                    :rules="[
                      (v) => v >= 0 || 'Horário inválido.',
                      (v) => v < 60 || 'Horário inválido.',
                    ]"
                    v-model="corte.fimSegundos"
                    class="pt-0 mt-8"
                    type="number"
                    @blur="audioFeedback = null"
                    @change="audioFeedback = null"
                    @click="audioFeedback = null"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" class="pt-5">
                  <v-btn
                    icon
                    large
                    color="primary"
                    @click="playSprite()"
                    v-if="!spriteStatus"
                    :disabled="audioFeedback ? true : false"
                  >
                    <v-icon>mdi-play</v-icon>
                  </v-btn>
                  <v-btn icon large color="primary" @click="pauseSprite()" v-if="spriteStatus">
                    <v-icon>mdi-pause</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-switch
                    v-model="okAudio"
                    :label="okAudio ? 'Áudio Verificado e OK' : 'Áudio com problemas'"
                    inset
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-switch
                    v-model="okContent"
                    :label="okContent ? 'Conteúdo Verificado e OK' : 'Conteúdo com problemas'"
                    inset
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-if="!okAudio"
                    v-model="selectedItem.audioProblem"
                    :rules="[(v) => !!v || 'Insira o motivo do Áudio não estar ok.']"
                    label="Informe o problema do ÁUDIO"
                    class="pt-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-if="!okContent"
                    v-model="selectedItem.contentProblem"
                    :rules="[(v) => !!v || 'Insira o motivo do Conteúdo não estar ok.']"
                    label="Informe o problema no CONTEÚDO"
                    class="pt-0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="primary" @click="downloadAudio()">Download</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            :disabled="loading"
            @click="
              uploadDialog = false;
              editingAudio = false;
              clearAudio();
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            :disabled="!selectedAudio ? (editingAudio ? false : true) : false"
            @click="doUpload()"
          >
            Upload/Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal Clone -->
    <v-dialog v-model="cloneDialog" max-width="500px">
      <v-card v-if="selectedItem">
        <v-card-title class="text-h5"> Confirma Duplicação da Cencura? </v-card-title>
        <v-card-subtitle class="pt-2 pb-0">
          <strong>Id: </strong>{{ selectedItem.idCamp }} <span class="mx-2"> - </span>
          <strong>Pi: </strong>{{ selectedItem.piVeiculo }} <span class="mx-2"> - </span>
          <strong>IdSecom: </strong>{{ selectedItem.idSecom }}
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="cloneDialog = false">Cancelar</v-btn>
          <v-btn color="blue darken-1" text :loading="loading" @click="doClone()"> Duplicar </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal delete -->
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card v-if="selectedItem">
        <v-card-title class="text-h5"> Deseja realmente deletar essa Censura? </v-card-title>
        <v-card-subtitle class="pt-2 pb-0">
          <strong>Id: </strong>{{ selectedItem.idCamp }} <span class="mx-2"> - </span>
          <strong>Pi: </strong>{{ selectedItem.piVeiculo }} <span class="mx-2"> - </span>
          <strong>IdSecom: </strong>{{ selectedItem.idSecom }}
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">Cancelar</v-btn>
          <v-btn color="blue darken-1" text :loading="loading" @click="doDelete()">Deletar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- import table dialog -->
    <v-dialog
      v-model="importTableDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="resetImport()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Importar Planilha de Censuras</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text :disabled="!inputFile && !censuresData.length" @click="saveCensures()">
              Salvar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader v-if="inputFile">
          <v-subheader>Arquivo Selecionado</v-subheader>
        </v-list>
        <v-card-text>
          <v-file-input
            v-model="inputFile"
            :rules="[
              (v) => !!v || 'Selecione uma planilha para importação!',
              (v) => !v || v.size < 2000000 || 'A planilha precisa ter menos de 2 MB!',
            ]"
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
            placeholder="Selecione uma planilha"
            prepend-icon="mdi-microsoft-excel"
            label="Importar Planilha"
            :loading="fileLoading"
            :disabled="fileLoading"
            :class="{ 'mt-8': !inputFile }"
            show-size
            @click:clear="censuresData = []"
            @change="changeFileInput()"
          ></v-file-input>
        </v-card-text>
        <v-card-text>
          <v-simple-table
            v-if="inputFile && censuresData.length"
            fixed-header
            :dense="isMobile ? true : false"
            :height="
              isMobile
                ? censuresData.length > 10
                  ? 674
                  : '100%'
                : censuresData.length > 10
                ? 530
                : '100%'
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="primary darken-2 text-left white--text">ID. CAMP.</th>
                  <th class="primary darken-2 text-left white--text">AGÊNCIA</th>
                  <th class="primary darken-2 text-left white--text">CLIENTE</th>
                  <th class="primary darken-2 text-left white--text">ID SECOM</th>
                  <th class="primary darken-2 text-left white--text">Pi CAMP.</th>
                  <th class="primary darken-2 text-left white--text">CAMPANHA</th>
                  <th class="primary darken-2 text-left white--text">PERÍODO</th>
                  <th class="primary darken-2 text-left white--text">Pi VEÍCULO</th>
                  <th class="primary darken-2 text-left white--text">UF</th>
                  <th class="primary darken-2 text-left white--text">MUNICÍPIO</th>
                  <th class="primary darken-2 text-left white--text">VEÍCULO</th>
                  <th class="primary darken-2 text-left white--text">FX</th>
                  <th class="primary darken-2 text-left white--text">RX</th>
                  <th class="primary darken-2 text-left white--text">PROGRAMA</th>
                  <th class="primary darken-2 text-left white--text">APRESENTADOR</th>
                  <th class="primary darken-2 text-left white--text">INÍCIO</th>
                  <th class="primary darken-2 text-left white--text">FIM</th>
                  <th class="primary darken-2 text-left white--text">VEICULAÇÃO</th>
                  <th class="primary darken-2 text-left white--text">#INSERÇÃO</th>
                  <th class="primary darken-2 text-left white--text">#TEXTO</th>
                  <th class="primary darken-2 text-left white--text">CONTEÚDO</th>
                  <th class="primary darken-2 text-left white--text">TIPO</th>
                  <th class="primary darken-2 text-left white--text">FORMATO</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(censure, i) in censuresData"
                  :key="i"
                  :class="{ grey: i % 2, 'lighten-5': i % 2 }"
                >
                  <td class="text-left">{{ censure.idCamp }}</td>
                  <td class="text-left">{{ censure.agencia }}</td>
                  <td class="text-left">{{ censure.cliente }}</td>
                  <td class="text-left">{{ censure.idSecom }}</td>
                  <td class="text-left">{{ censure.piCamp }}</td>
                  <td class="text-left">{{ censure.campanha }}</td>
                  <td class="text-left">{{ censure.periodo }}</td>
                  <td class="text-left">{{ censure.piVeiculo }}</td>
                  <td class="text-left">{{ censure.uf }}</td>
                  <td class="text-left">{{ censure.municipio }}</td>
                  <td class="text-left">{{ censure.veiculo }}</td>
                  <td class="text-left">{{ censure.fx }}</td>
                  <td class="text-left">{{ censure.rx }}</td>
                  <td class="text-left">{{ censure.programa }}</td>
                  <td class="text-left">{{ censure.apresentador }}</td>
                  <td class="text-left">{{ censure.inicio }}</td>
                  <td class="text-left">{{ censure.fim }}</td>
                  <td class="text-left">
                    {{ censure.veiculacao.toLocaleDateString("pt-BR") }}
                  </td>
                  <td class="text-left">{{ censure.numInsercao }}</td>
                  <td class="text-left">{{ censure.numTexto }}</td>
                  <td class="text-left">{{ censure.conteudo }}</td>
                  <td class="text-left">{{ censure.tipo }}</td>
                  <td class="text-left">{{ censure.formato }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
import { mxSlugify } from "@/mixins/SlugifyMixin";
// import { audioBufferSlice } from "audiobuffer-slice";
import { db, collection, getDocs } from "@/firebase/firebase";

export default {
  name: "Panel",

  mixins: [mxSlugify],

  components: {
    //
  },

  mounted() {
    if (this.currentUser && !this.campaigns) this.getUserDataByRole(this.currentUser);
    if (this.campaigns) this.setCampaignItems(this.campaigns);
  },

  destroyed() {
    if (this.campaigns) this.$store.state.campaign = null;
  },

  data() {
    return {
      selectedCampaign: null,
      campaignsNames: [],
      headers: [
        {
          text: "Id Campanha",
          align: "start",
          sortable: true,
          value: "idCamp",
        },
        {
          text: "Data Veic.",
          sortable: true,
          value: "veiculacao",
        },
        {
          text: "Agência",
          sortable: true,
          value: "agencia",
        },
        {
          text: "iD Secom",
          sortable: true,
          value: "idSecom",
        },
        {
          text: "Pi Veículo",
          sortable: true,
          value: "piVeiculo",
        },
        {
          text: "Estado",
          sortable: true,
          value: "uf",
        },
        {
          text: "Município",
          sortable: true,
          value: "municipio",
        },
        {
          text: "Veículo",
          sortable: true,
          value: "veiculo",
        },
        { text: "Áudio", value: "audio", sortable: false, align: "center" },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
      editDialog: false,
      deleteDialog: false,
      uploadDialog: false,
      cloneDialog: false,
      importTableDialog: false,
      search: "",
      expanded: [],
      arrCensures: null,
      censuresData: [],
      inputFile: null,
      fileLoading: null,
      selectedItem: null,
      isValid: true,
      okAudio: null,
      okContent: null,
      selectedAudio: null,
      locSubst: false,
      timeMenu: false,
      selectedTime: null,
      corte: {
        inicioMinutos: 0,
        inicioSegundos: 0,
        fimMinutos: 0,
        fimSegundos: 0,
      },
      audioFeedback: null,
      editingAudio: false,
      // audioProblem: null,
      // contentProblem: null,
      spriteStatus: null,
    };
  },

  computed: {
    campaign() {
      return this.$store.getters.getCampaign;
    },
    campaigns() {
      return this.$store.getters.getCampaigns;
    },
    currentUser() {
      return this.$store.getters.getUser;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
    cutSeconds() {
      const initialTime =
        parseInt(this.corte.inicioMinutos) * 60 + parseInt(this.corte.inicioSegundos);
      const finalTime = parseInt(this.corte.fimMinutos) * 60 + parseInt(this.corte.fimSegundos);
      const result = finalTime - initialTime;
      return result ? result : 0;
    },
  },

  watch: {
    currentUser(user) {
      if (user) this.getUserDataByRole(user);
    },
    campaigns(val) {
      if (val) this.setCampaignItems(val);
    },
  },

  methods: {
    async downloadAudio() {
      console.log("click");
      const player = this.$refs.audioPlayer;
      fetch(player.src, { mode: "no-cors" })
        .then((response) => {
          console.log(response);
          response.arrayBuffer().then((dataBuffer) => {
            console.log("blob?", dataBuffer);
            // audioBufferSlice(dataBuffer, 10000, 15000, function(
            //   error,
            //   slicedAudioBuffer
            // ) {
            //   if (error) {
            //     console.error(error);
            //   } else {
            //     const sourceBuffer = slicedAudioBuffer;
            //     console.log(sourceBuffer);
            //   }
            // });
          });
        })
        .catch(console.log);

      const arrayCensures = [];

      const querySnapshot = await getDocs(
        collection(
          db,
          "campaigns",
          "campanha-nacional-de-multivacinacao-para-criancas-e-adolescentes",
          "censuresData"
        )
      );
      querySnapshot.forEach((doc) => {
        const newCensure = { ...doc.data() };
        newCensure.id = doc.id;
        arrayCensures.push(newCensure);
      });
      console.log(arrayCensures);
    },
    getColor(status) {
      let color = "grey";
      if (status == 3) color = "green";
      if (status == 2) color = "orange";
      if (status == 1) color = "red";
      return color;
    },
    getUserDataByRole(user) {
      const id = user.role == "admin" ? user.id : user.employerId;
      this.$store.dispatch("getAllCampaignsFromUser", id);
    },
    playSprite() {
      const player = this.$refs.audioPlayer;
      const initialTime =
        parseInt(this.corte.inicioMinutos) * 60 + parseInt(this.corte.inicioSegundos);
      let finalTime = parseInt(this.corte.fimMinutos) * 60 + parseInt(this.corte.fimSegundos);
      if (finalTime < 1) finalTime = player.duration;
      if (initialTime > finalTime)
        this.audioFeedback = "Tempo inicial do corte está maior que o tempo final...";
      if (player && initialTime <= finalTime) {
        player.currentTime = initialTime;
        this.spriteStatus = true;
        player.play();
        const playInterval = setInterval(() => {
          if (player.currentTime >= finalTime) {
            player.pause();
            clearInterval(playInterval);
            this.spriteStatus = false;
          }
        }, 1000);
      }
    },
    pauseSprite() {
      const player = this.$refs.audioPlayer;
      // console.log(player.paused);
      if (player) {
        player.pause();
        this.spriteStatus = false;
      }
    },
    checkTime() {
      const initialCut =
        parseInt(this.corte.inicioMinutos) * 60 + parseInt(this.corte.inicioSegundos);
      const finalCut = parseInt(this.corte.fimMinutos) * 60 + parseInt(this.corte.fimSegundos);
      if (isNaN(initialCut) || isNaN(finalCut)) {
        return false;
      } else {
        if (initialCut == 0 && finalCut == 0) return true;
        if (initialCut > 0 && finalCut == 0) return true;
        if (initialCut >= finalCut) return false;
      }
      return true;
    },
    getUniqueId() {
      const dateString = Date.now().toString(36);
      const randomness = Math.random().toString(36).substr(2);
      return dateString + randomness;
    },
    clearAudio() {
      this.selectedItem = null;
      this.editingAudio = null;
      this.selectedAudio = null;
      this.$refs.audioPlayer.src = null;
      this.audioFeedback = null;
      this.selectedTime = null;
      this.locSubst = false;
      this.corte = {
        inicioMinutos: 0,
        inicioSegundos: 0,
        fimMinutos: 0,
        fimSegundos: 0,
      };
    },
    previewAudio() {
      if (this.selectedAudio) {
        let reader = new FileReader();
        reader.readAsDataURL(this.selectedAudio);
        reader.addEventListener("load", () => {
          this.$refs.audioPlayer.src = reader.result;
        });
      }
    },
    cloneItem(item) {
      this.cloneDialog = true;
      this.selectedItem = { ...item };
    },
    doClone() {
      const index = this.campaign.censuresData.findIndex((obj) => {
        return obj.idApp == this.selectedItem.idApp;
      });
      if (index >= 0) {
        this.selectedItem.idApp = this.getUniqueId();
        delete this.selectedItem.audioData;
        delete this.selectedItem.audioProblem;
        delete this.selectedItem.contentProblem;
        delete this.selectedItem.status;
        delete this.selectedItem.id;
        this.campaign.censuresData.splice(index + 1, 0, {
          ...this.selectedItem,
        });
        console.log("clone", this.selectedItem);
      }
      const payload = {};
      payload.campaignId = this.campaign.id;
      payload.censure = { ...this.selectedItem };
      const parts = payload.censure.veiculacao.split("/");
      const newDate = new Date(parts[2], parts[1] - 1, parts[0]);
      payload.censure.veiculacao = newDate;

      this.$store
        .dispatch('addSingleCensureToSubCollection', payload)
        .then((docId) => {
          this.campaign.censuresData[index].id = docId;
          // this.normalizeDate();
          this.cloneDialog = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    desnormalizeDate(censures) {
      censures.forEach((censure) => {
        const parts = censure.veiculacao.split("/");
        const newDate = new Date(parts[2], parts[1] - 1, parts[0]);
        censure.veiculacao = newDate;
      });
      return censures;
    },
    normalizeDate() {
      this.campaign.censuresData.forEach((censure) => {
        censure.veiculacao = censure.veiculacao.toLocaleDateString("pt-BR");
      });
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.selectedItem = { ...item };
    },
    doDelete() {
      this.campaign.censuresData = this.campaign.censuresData.filter((obj) => {
        return obj.idApp !== this.selectedItem.idApp;
      });
      const payload = {};
      // payload.censuresData = [...this.campaign.censuresData];
      // payload.censuresData = this.desnormalizeDate(this.campaign.censuresData);
      payload.campaignId = this.campaign.id;
      payload.docId = this.selectedItem.id;
      this.$store
        .dispatch("deleteSingleCensureFromSubCollection", payload)
        .then(() => {
          // this.normalizeDate();
          this.deleteDialog = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    publishItem(item) {
      this.selectedItem = { ...item };
      this.selectedItem.status = 2;
      const index = this.campaign.censuresData.findIndex((obj) => {
        return obj.idApp == this.selectedItem.idApp;
      });
      if (index >= 0) {
        this.campaign.censuresData = this.campaign.censuresData.filter((obj) => {
          return obj.idApp !== this.selectedItem.idApp;
        });
        this.campaign.censuresData.splice(index, 0, { ...this.selectedItem });
      }
      const payload = {};
      payload.censuresData = [...this.campaign.censuresData];
      payload.censuresData = this.desnormalizeDate(this.campaign.censuresData);
      payload.campaignId = this.campaign.id;
      payload.censure = payload.censuresData[index];
      this.$store
        .dispatch("updateSingleCensureInSubCollection", payload)
        .then((docId) => {
          this.campaign.censuresData[index].id = docId;
          this.normalizeDate();
          this.editDialog = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    unPublishItem(item) {
      this.selectedItem = { ...item };
      this.selectedItem.status = 0;
      const index = this.campaign.censuresData.findIndex((obj) => {
        return obj.idApp == this.selectedItem.idApp;
      });
      if (index >= 0) {
        this.campaign.censuresData = this.campaign.censuresData.filter((obj) => {
          return obj.idApp !== this.selectedItem.idApp;
        });
        this.campaign.censuresData.splice(index, 0, { ...this.selectedItem });
      }
      const payload = {};
      payload.censuresData = [...this.campaign.censuresData];
      payload.censuresData = this.desnormalizeDate(this.campaign.censuresData);
      payload.campaignId = this.campaign.id;
      payload.censure = payload.censuresData[index];
      this.$store
        .dispatch("updateSingleCensureInSubCollection", payload)
        .then((docId) => {
          this.campaign.censuresData[index].id = docId;
          this.normalizeDate();
          this.editDialog = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    editItem(item) {
      this.selectedItem = { ...item };
      this.editDialog = true;
    },
    doEdit() {
      const index = this.campaign.censuresData.findIndex((obj) => {
        return obj.idApp == this.selectedItem.idApp;
      });
      if (index >= 0) {
        this.campaign.censuresData = this.campaign.censuresData.filter((obj) => {
          return obj.idApp !== this.selectedItem.idApp;
        });
        this.campaign.censuresData.splice(index, 0, { ...this.selectedItem });
      }
      const payload = {};
      payload.censuresData = [...this.campaign.censuresData];
      payload.censuresData = this.desnormalizeDate(this.campaign.censuresData);
      payload.campaignId = this.campaign.id;
      payload.censure = payload.censuresData[index];
      this.$store
        .dispatch("updateSingleCensureInSubCollection", payload)
        .then((docId) => {
          this.campaign.censuresData[index].id = docId;
          this.normalizeDate();
          this.editDialog = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    editAudio(item) {
      console.log("item", item);
      if (
        this.currentUser.role == "admin" ||
        item.audioData.operatorId == this.currentUser.id ||
        !item.audioData.operatorId
      ) {
        this.uploadDialog = true;
        this.editingAudio = true;
        this.selectedItem = { ...item };
        this.okAudio = this.selectedItem.audioData.okAudio;
        this.okContent = this.selectedItem.audioData.okContent;
        this.selectedTime = this.selectedItem.audioData.time;
        this.corte = { ...this.selectedItem.audioData.cut };
        this.locSubst = this.selectedItem.audioData.substituto;
      }
    },
    uploadAudio(item) {
      this.uploadDialog = true;
      this.selectedItem = { ...item };
      this.okAudio = true;
      this.okContent = true;
      this.selectedItem.audioData = {};
      // console.log(this.selectedItem);
    },
    doUpload() {
      if (!this.selectedTime) this.audioFeedback = "Insira um Horário para continuar...";
      if (!this.selectedAudio && !this.editingAudio)
        this.audioFeedback = "Selecione um arquivo de Áudio para continuar...";

      if (this.$refs.uploadForm.validate() && !this.audioFeedback) {
        const payload = {};
        payload.campaignId = this.selectedCampaign.id;
        payload.campaignIdApp = this.selectedItem.idApp;
        payload.file = this.selectedAudio;

        const newAudioData = {};
        newAudioData.time = this.selectedTime;
        newAudioData.substituto = this.locSubst ? true : false;
        if (newAudioData.substituto) newAudioData.apresentador = this.selectedItem.apresentador;
        newAudioData.startPoint =
          parseInt(this.corte.inicioMinutos) * 60 + parseInt(this.corte.inicioSegundos);
        newAudioData.endPoint =
          parseInt(this.corte.fimMinutos) * 60 + parseInt(this.corte.fimSegundos);
        newAudioData.okAudio = this.okAudio;

        newAudioData.okContent = this.okContent;
        if (!newAudioData.okAudio) newAudioData.audioProblem = this.selectedItem.audioProblem;
        if (!newAudioData.okContent) newAudioData.contentProblem = this.selectedItem.contentProblem;
        newAudioData.cut = {
          inicioMinutos: parseInt(this.corte.inicioMinutos),
          inicioSegundos: parseInt(this.corte.inicioSegundos),
          fimMinutos: parseInt(this.corte.fimMinutos),
          fimSegundos: parseInt(this.corte.fimSegundos),
        };
        if (this.selectedAudio) newAudioData.originalFileName = this.selectedAudio.name;
        if (this.selectedItem.audioData && this.selectedItem.audioData.audioUrl)
          newAudioData.audioUrl = this.selectedItem.audioData.audioUrl;
        if (this.currentUser.role != "admin") {
          newAudioData.operatorName = this.currentUser.name;
          newAudioData.operatorId = this.currentUser.id;
        } else {
          if (this.selectedItem.audioData.operatorName)
            newAudioData.operatorName = this.selectedItem.audioData.operatorName;
          if (this.selectedItem.audioData.operatorId)
            newAudioData.operatorId = this.selectedItem.audioData.operatorId;
        }

        let slugfileName =
          this.mxSlugify(this.selectedItem.idSecom) +
          "_" +
          this.mxSlugify(this.selectedItem.uf) +
          "_" +
          this.mxSlugify(this.selectedItem.municipio) +
          "_" +
          this.mxSlugify(this.selectedItem.veiculo) +
          "_" +
          this.mxSlugify(this.selectedItem.programa) +
          "_" +
          this.mxSlugify(this.selectedItem.veiculacao) +
          "_" +
          this.mxSlugify(this.selectedItem.numInsercao) +
          "_" +
          this.mxSlugify(this.selectedItem.numTexto) +
          "_" +
          this.mxSlugify(newAudioData.time) +
          "_" +
          this.mxSlugify(this.selectedItem.apresentador) +
          ".mp3";

        // "3731_sc_florianopolis_regional-fm_alo-regional_27-09-2021_i1_t1_22-49_luiz-carlos-lucena.mp3"

        payload.slugFileName = slugfileName;

        this.selectedItem.audioData = newAudioData;

        if (newAudioData.okAudio && newAudioData.okContent) {
          this.selectedItem.status = 3; // audio ok (publica)
        } else {
          this.selectedItem.status = 1; // audio em análise (não publica)
        }

        const index = this.campaign.censuresData.findIndex((obj) => {
          return obj.idApp == this.selectedItem.idApp;
        });
        if (index >= 0) {
          this.campaign.censuresData = this.campaign.censuresData.filter((obj) => {
            return obj.idApp !== this.selectedItem.idApp;
          });
          this.campaign.censuresData.splice(index, 0, {
            ...this.selectedItem,
          });
        }
        payload.censuresData = this.desnormalizeDate(this.campaign.censuresData);
        payload.censure = payload.censuresData[index];

        if (!payload.file) {
          this.$store
            .dispatch("updateSingleCensureInSubCollection", payload)
            .then((docId) => {
              this.campaign.censuresData[index].id = docId;
              this.normalizeDate();
              this.uploadDialog = false;
              this.clearAudio();
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          // console.log('payload', payload)
          this.$store
            .dispatch("uploadAudioFileToStorage", payload)
            .then((returnedUrl) => {
              payload.censuresData[index].audioData.audioUrl = returnedUrl;
              this.$store.dispatch("updateSingleCensureInSubCollection", payload).then((docId) => {
                this.campaign.censuresData[index].id = docId;
                this.normalizeDate();
                this.uploadDialog = false;
                this.clearAudio();
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    },
    resetImport() {
      this.importTableDialog = false;
      this.censuresData = [];
      this.inputFile = null;
      this.fileLoading = null;
    },
    saveCensures() {
      // console.log("Ué?", this.inputFile, this.censuresData.length);
      if (this.inputFile && this.censuresData.length) {
        const data = {
          campaignId: this.campaign.id,
          censuresData: this.censuresData,
        };
        this.$store
          .dispatch("addCensureDataToSubcollection", data)
          .then(() => {
            this.resetImport();
            this.$store.dispatch("loadCampaignWithCensuresData", this.campaign.id);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        console.log("not save");
      }
    },
    changeFileInput() {
      const file = this.inputFile;
      if (file) {
        this.fileLoading = true;

        file
          .arrayBuffer()
          .then((res) => {
            const data = new Uint8Array(res);
            const workbook = XLSX.read(data, { type: "array" });
            const workSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(workSheet, {
              header: [
                "idCamp",
                "agencia",
                "cliente",
                "idSecom",
                "piCamp",
                "campanha",
                "periodo",
                "piVeiculo",
                "uf",
                "municipio",
                "veiculo",
                "fx",
                "rx",
                "programa",
                "apresentador",
                "inicio",
                "fim",
                "veiculacao",
                "numInsercao",
                "numTexto",
                "conteudo",
                "tipo",
                "formato",
              ],
              raw: true,
            });
            jsonData.shift();
            //console.log(jsonData);
            jsonData.forEach((censure) => {
              censure.idApp = this.getUniqueId();
              censure.inicio = this.decimalToHourMinutes(censure.inicio);
              censure.fim = this.decimalToHourMinutes(censure.fim);
              censure.veiculacao = new Date(
                Math.round((censure.veiculacao - 25569) * 86400 * 1000) +
                  Math.round(24 * 60 * 60 * 1000)
              );
              // console.log(censure.veiculacao);
              this.censuresData.push(censure);
            });
            this.fileLoading = false;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    decimalToHourMinutes(decNum) {
      if (decNum != 17) {
        decNum = decNum * 24;
      } else {
        decNum = "00";
      }
      const strNum = decNum + "";
      if (strNum.indexOf(".") != -1) {
        const strPercent = strNum.substring(strNum.indexOf(".") + 1);
        const percent = strPercent / 100;
        const hours = strNum.substring(0, strNum.indexOf("."));
        let strHours = hours + "";
        if (strHours.length < 2) strHours = "0" + strHours;
        const min = Math.ceil(60 * percent);
        let strMin = min + "";
        if (strMin.length > 2) strMin = strMin.substring(0, 2);
        if (strMin.length < 2) strMin = strMin + "0";
        return `${strHours}:${strMin}`;
      } else {
        let strHours = decNum + "";
        if (strHours.length < 2) strHours = "0" + strHours;
        return `${strHours}:00`;
      }
    },
    loadSelectedCampaign() {
      // console.log("change", this.selectedCampaign);
      this.$store
        .dispatch("loadCampaignWithCensuresData", this.selectedCampaign.id)
        .then(() => {
          this.arrCensures = [];
          // console.log(this.campaign);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setCampaignItems(campaigns) {
      campaigns.forEach((campaign) => {
        this.campaignsNames.push({
          name: campaign.name,
          id: campaign.id,
        });
      });
      if (this.campaign) {
        this.selectedCampaign = {
          name: this.campaign.name,
          id: this.campaign.id,
        };
      }
    },
  },
};
</script>

<style scoped>
.Panel {
  padding: 20px;
}
.caption-play {
  transform: translateY(-40px);
}
</style>
